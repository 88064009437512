<template>
<div class="mt-4 row">
  <div class="col-md-7">
    <div class="card shadow mt-2">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <text-input
                :value="name.value"
                :errors="errors.name"
                :label="name.label"
                :placeholder="name.placeholder"
                @type="(v) => (name.value = v)"
                :is_password="false"
                :required="true"
            ></text-input>
          </div>
          <div class="col-md-12">
            <text-input
                :value="kin_name.value"
                :errors="errors.kin_name"
                :label="kin_name.label"
                :placeholder="kin_name.placeholder"
                @type="(v) => (kin_name.value = v)"
                :is_password="false"
                :required="true"
            ></text-input>
          </div>
          <div class="col-md-7">
            <LoadingButton text="Submit" @on-click="saveCategory"></LoadingButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5">
    <div class="card shadow mt-2">
      <div class="card-body">
        <div class="row">
           <div class="col-md-12">
             <h3>Actions</h3>
             <hr/>
           </div>
          <div class="col-md-12">
            <LoadingButton text="Delete" class-name="btn btn-block btn-warning" @on-click="deleteTrigger"></LoadingButton>
          </div>

        </div>
      </div>
    </div>
  </div>


</div>
</template>

<script>
import axios from "axios";
import TextInput from "../../components/TextInput";
import LoadingButton from "@/components/Buttons/LoadingButton";
import { alert } from "../../utils/alertUtils";
export default {
  name: "ManageCategory",
  components:{
    LoadingButton,
    "text-input": TextInput,
  },
  props:['uuid'],
  data(){
    return{
      category:{},
      name: {
        value: "",
        errors: [],
        label: "Category Name:",
        placeholder: "Enter the category name",
      },
      kin_name: {
        value: "",
        errors: [],
        label: "Kinyarwanda translation Name:",
        placeholder: "Enter Kinyarwanda translation of category name",
      },
      post_data:{
        name:'',
        kin_name:'',
        pctype:'Product'
      },
      errors: {},
    }
  },
  methods:{
    view_catgory:function () {
      let url = `${process.env.VUE_APP_BACKEND_URL}/api/parentCategories/${this.uuid}`;
      axios
          .get(url, {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          })
          .then((res) => {

            this.category=res.data
            this.kin_name.value=res.data.kin_name
            this.name.value=res.data.name
          })
          .catch((err) => {
            console.log(err);
          });
    },
    saveCategory:function () {
      let url = `${process.env.VUE_APP_BACKEND_URL}/api/parentCategories/${this.uuid}`;
      this.post_data.kin_name=this.kin_name.value
      this.post_data.name=this.name.value
      axios
          .put(
               url,
                this.post_data, {
                headers: {
                  Authorization: "Bearer " + this.$store.getters.getToken,
                },
               })
          .then(() => {
            this.view_catgory()
            alert.success_center('Category updated')
          })
          .catch((err) => {
            if (err.response.status === 400) {
              this.errors = err.response.data;
            }else{
              alert.error('Error occurred, try again')
            }
          });
    },
    deleteTrigger:function () {
      alert.promptDelete(this.deleteCategory,this.uuid)
    },
    deleteCategory:function (uuid) {
      let url = `${process.env.VUE_APP_BACKEND_URL}/api/parentCategories/${uuid}`;
      axios
          .delete(url, {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          })
          .then(() => {
            alert.success_center('Category is deleted')
            this.$router.push({ name: "Categories" });
          })
          .catch((err) => {
            if(err.response.status==400){
              alert.error(err.response.data)
            }else{
              alert.error('Error occurred, try again')
            }
          });
    }
  },
  created() {
    this.view_catgory()
  }
}
</script>

<style scoped>

</style>