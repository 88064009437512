<template>
 <button :class="className" @click="(evt)=>$emit('on-click',evt)">
   <span class="fa fa-spin" v-if="loading"></span>
   <span :class="iconClass" v-if="iconClass!==null"></span>
   {{text}}
 </button>
</template>

<script>
export default {
  name: "LoadingButton",
  props:{
    text:{
      default:'',
      type:String
    },
    className:{
      default: 'btn btn-primary',
      type:String
    },
    loading:{
      default:false,
      type:Boolean
    },
    iconClass:{
      type:String,
      default:null
    },
  },

}
</script>

<style scoped>

</style>